.user-profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-profile h2,
.user-profile h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.profile-info p {
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.digest-preferences,
.subscribed-channels {
  margin-top: 2rem;
}

.digest-preferences select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--text-light);
  border-radius: 4px;
  background-color: var(--surface-color);
  color: var(--text-color);
}

.channel-list {
  display: flex;
  flex-direction: column;
}

.channel-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--surface-light);
  border-radius: 4px;
}

.channel-item input[type="checkbox"] {
  margin-right: 0.5rem;
}

.channel-item span {
  color: var(--text-color);
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

/* Dark mode specific styles */
.dark .channel-item {
  background-color: var(--dark-surface-color);
}

.dark .channel-item span,
.dark .email-notifications label {
  color: var(--dark-text-color);
}

.email-notifications {
  margin-top: 2rem;
}

.email-notifications-form {
  display: flex;
  align-items: center;
}

.email-notifications label {
  display: flex;
  align-items: center;
  color: var(--text-color);
  cursor: pointer;
}

.email-notifications input[type="checkbox"] {
  margin-right: 0.5rem;
}

.preferences-form {
  margin-top: 2rem;
}

.preferences-form > div {
  margin-bottom: 1.5rem;
}

.preferences-form button[type="submit"] {
  margin-top: 1rem;
}
