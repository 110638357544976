.share-link-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.share-link-modal h2 {
  margin-bottom: 15px;
}

.share-link-modal form {
  display: flex;
  flex-direction: column;
}

.share-link-modal input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.share-link-modal button {
  padding: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.share-link-modal button:disabled {
  background-color: #ccc;
}

.share-link-modal .close-button {
  margin-top: 10px;
  background-color: #f0f0f0;
  color: #333;
}
