.auth-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-container h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
}

.auth-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-container .input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--text-light);
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.auth-container .input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--transparent-primary-dark-20);
}

.auth-container .btn {
  margin-top: 1rem;
}

.auth-toggle {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
  transition: color 0.3s ease;
}

.auth-toggle:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}
