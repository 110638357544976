.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid var(--surface-light);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-spinner-container.small .loading-spinner {
  width: 20px;
  height: 20px;
}

.loading-spinner-container.medium .loading-spinner {
  width: 40px;
  height: 40px;
}

.loading-spinner-container.large .loading-spinner {
  width: 60px;
  height: 60px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
