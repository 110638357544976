.mobile-menu {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--surface-color);
  z-index: var(--z-index-modal);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.mobile-menu.open {
  transform: translateX(0);
}

.mobile-menu .main-nav,
.mobile-menu .user-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-menu .nav-link,
.mobile-menu .welcome-message,
.mobile-menu .btn {
  margin: 0.5rem 0;
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.mobile-menu .nav-link:hover,
.mobile-menu .welcome-message:hover,
.mobile-menu .btn:hover {
  background-color: var(--surface-light);
}

.mobile-menu .btn {
  text-align: center;
}

.mobile-menu .welcome-message {
  font-size: 1rem;
  font-weight: 500;
}

.mobile-menu .user-actions {
  margin-top: 1rem;
}

.mobile-menu .btn-icon {
  align-self: flex-start;
  margin-top: 1rem;
}

.mobile-menu .main-nav {
  border-bottom: 1px solid var(--text-light);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

/* Dark theme adjustments */
.dark .mobile-menu {
  background-color: var(--dark-surface-color);
}

.dark .mobile-menu .nav-link:hover,
.dark .mobile-menu .welcome-message:hover,
.dark .mobile-menu .btn:hover {
  background-color: var(--dark-surface-light);
}
