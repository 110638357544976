.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--surface-color);
  color: var(--text-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: var(--z-index-tooltip);
}

.dark .tooltip-content {
  background-color: var(--dark-surface-color);
  color: var(--dark-text-color);
}
