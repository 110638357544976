/* Base mobile styles */
@media (max-width: 767px) {
  .container {
    padding: 0 0.5rem;
  }

  .btn {
    width: 100%;
  }

  .form {
    flex-direction: column;
  }

  .input-wrapper,
  .input-wrapper:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .dropdown-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu li {
    padding: 15px;
    text-align: center;
  }

  .emoji-picker-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .emoji-picker-react {
    width: 100% !important;
    position: fixed !important;
    bottom: 0 !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}
